<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
        <div class="col col-lg-2"></div>
        <div class="col-md-auto mt-20">
          <rotate-square5></rotate-square5>
        </div>
        <div class="col col-lg-2"></div>
      </div>
      <div class="card card-body printableArea" v-else>
        <h3>
          <div class="row">
            <div class="col-md-10 col-12">
              <b>Status Pemesanan</b>
            </div>
            <br/>
            
              <div class="container">
                <div class="row justify-content-md-center">
                  <div class="col-md-12">
                    <form method="post" @submit.prevent="changeDt">
                      <div class="form-body">
                        <div class="row justify-content-md-center">
                          <div class="col-md-9 col-sm-12">
                            <div class="row">
                              <div class="form-group" style="width: 378px">
                                <input placeholder="Pencarian Nama Ruangan" type="text" class="form-control" v-model="nama_ruangan" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row justify-content-md-center">
                          <div class="col-md-9 col-sm-12">
                            <div class="row">
                              <div class="form-group" style="width: 378px">
                                <input placeholder="Pencarian Nama Penanggungjawab" type="text" class="form-control" v-model="nama_unit" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row justify-content-md-center">
                          <div class="col-md-9 col-sm-12">
                            <div class="row">
                              <div class="form-group" style="width: 378px">
                                <input placeholder="Pencarian Kapasitas Ruangan" type="text" class="form-control" v-model="kapasitas_ruangan" />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row justify-content-md-center">
                          <div class="col-md-9 col-sm-12">
                            <div class="row">
                              <div class="form-group" style="width: 378px">
                                <treeselect placeholder="Pencarian Status Pemesanan" v-model="status_pemesanan" :options="options" />
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="form-actions">
                        <div class="row justify-content-md-center">
                          <div class="col-md-7 pl-5">
                            <button
                              type="submit"
                              class="btn btn-success"
                              title="Simpan"
                            >
                              Cari</button
                            >&nbsp;
                            <button
                              type="button"
                              @click="resetInputs()"
                              class="btn btn-inverse"
                              title="Reset"
                            >
                              Batal
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
          </div>
        </h3>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <Table
              :items="items"
              :filter="filters"
              :header="headers"
              :onAction="get"
              :onDetail="doDetail"
              :onEdit="doEdit"
              :onApprove="doApprove"
              :onBatalPesan="doBatalPesan"
              @onDelete="doDelete"
              :forbidApprove="forbidUpdate"
              :forbidDelete="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from "@/components/table/table";
import { RotateSquare5 } from "vue-loading-spinner";

export default {
  components: {
    RotateSquare5,
    Table,
  },
  data() {
    return {
      headers: {
        nama_kegiatan: {
          label: "Nama Kegiatan",
          sortable: true,
        },
        tanggal_kegiatan: {
          label: "Tanggal Kegiatan",
          sortable: true,
        },
        waktu: {
          label: "Waktu Kegiatan",
          sortable: true,
        },
        nama_ruangan: {
          label: "Nama Ruangan",
          sortable: true,
        },
        kapasitas_ruangan: {
          label: "Kapasitas Ruangan",
          sortable: true,
        },
        jumlah_peserta: {
          label: "Jumlah Peserta",
          sortable: true,
        },
        kontak_person: {
          label: "Kontak Person",
          sortable: true,
        },
        opd_pembuat: {
          label: "OPD",
          sortable: true,
        },
        status_kegiatan: {
          label: "Status Kegiatan",
          sortable: true,
        },
      },
      options: [],
      isTouched: false,
      isLoading: false,
      isDisabled: false,
    };
  },
  computed: {
    items() {
      return this.$store.state.meetingPesan.items
        ? this.$store.state.meetingPesan.items.items
        : [];
    },
    filters() {
      return this.$store.state.meetingPesan.items
        ? this.$store.state.meetingPesan.items.filters
        : {};
    },
    state() {
      return this.$store.state.meetingPesan;
    },
    forbidUpdate() {
      if (this.$store.state.profile.permissions.meetingPesan) {
        return !this.$store.state.profile.permissions.meetingPesan.update;
      }
      return false;
    },
    forbidRead() {
      if (this.$store.state.profile.permissions.meetingPesan) {
        return !this.$store.state.profile.permissions.meetingPesan.read;
      }
      return false;
    },
    forbidDel() {
      if (this.$store.state.profile.permissions.meetingPesan) {
        return !this.$store.state.profile.permissions.meetingPesan.delete;
      }
      return false;
    },
  },
  mounted() {
    const state = {
      loaded: false,
      items: [],
    };
    this.treeKlasifikasi();
    this.$store.commit("meetingPesan/STATE", state);
    this.get();
  },
  methods: {
    handleShowFilter() {
      this.showFilter = !this.showFilter;
    },
    treeKlasifikasi() {
      // axios.get(`/clasificationdoc/get_list_klasifikasi`).then((res) => {
      //   this.options = res.data.data;
      // });
      this.options = [
        { id: 1, label: "Disetujui" },
        { id: 0, label: "Tunggu" },
        { id: 2, label: "Ditolak / Dibatalkan" },
      ];
    },
    changeDt() {
      // this.$children[0].$refs.table.reload();
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        nama_ruangan: this.nama_ruangan,
        nama_unit: this.nama_unit,
        kapasitas_ruangan: this.kapasitas_ruangan,
        status_pemesanan: this.status_pemesanan,
        cari: this.cari,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      this.$store.commit("meetingPesan/STATE", state);
      this.get(state);
    },
    get(val) {
      this.$store.dispatch("meetingPesan/getMeetingPesan", val);
    },
    resetInputs() {
      this.cari = "";
      this.nama_ruangan = "";
      this.nama_unit = "";
      this.kapasitas_ruangan = "";
      this.status_pemesanan = "";
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        nama_ruangan: this.nama_ruangan,
        nama_unit: this.nama_unit,
        kapasitas_ruangan: this.kapasitas_ruangan,
        status_pemesanan: this.status_pemesanan,
        cari: this.cari,
        asal: this.asal,
      };
      this.$store.commit("meetingPesan/STATE", state);
      this.get(state);
      // this.$children[0].$refs.table.reload();
    },
    resetInput() {
      this.start_date = moment().startOf("year").format("YYYY-MM-DD");
      this.end_date = moment().endOf("year").format("YYYY-MM-DD");
      this.nomor_agenda = "";
      this.periode_surat = "";
      this.perihal = "";
      this.nomor_surat = "";
      this.nama_ruangan = "";
      this.nama_unit = "";
      this.kapasitas_ruangan = "";
      this.status_pemesanan = "";
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        nama_ruangan: this.nama_ruangan,
        nama_unit: this.nama_unit,
        kapasitas_ruangan: this.kapasitas_ruangan,
        status_pemesanan: this.status_pemesanan,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      this.$store.commit("meetingPesan/STATE", state);
      this.get(state);
      this.$children[0].$refs.table.reload();
    },
    filterData: function () {
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        start_date: this.start_date,
        end_date: this.end_date,
        nama_ruangan: this.nama_ruangan,
        nama_unit: this.nama_unit,
        kapasitas_ruangan: this.kapasitas_ruangan,
        status_pemesanan: this.status_pemesanan,
      };
      this.$store.commit("meetingPesan/STATE", state);
      this.get(state);
    },
    doDetail(val) {
      this.$store.dispatch("meetingPesan/onDetail", val);
    },
    doEdit(val) {
      this.$store.dispatch("meetingPesan/onEdit", val);
    },
    doApprove(val) {
      this.$store.dispatch("meetingPesan/submitApprove", val);
    },
    doBatalPesan(val) {
      this.$store.dispatch("meetingPesan/submitBatalPesan", val);
    },
    doEdit(val) {
      this.$store.dispatch("meetingPesan/onEdit", val);
    },
    doPemesanan(val) {
      this.$store.dispatch("meetingPesan/onPemesanan", val);
    },
    doDelete(val) {
      this.$store.dispatch("meetingPesan/submitDelete", val);
    },
    getFrom() {
      const state = {
        search: this.search,
        from: this.from,
      };
      if (this.from == "pencarian_khusus") {
        this.showFilter = !this.showFilter;
      }
      this.$store.commit("meetingPesan/STATE", state);
      this.get(state);
    },
  },
};
</script>
